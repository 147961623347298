import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import id from './locales/id.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import zh from './locales/zh.json';

Vue.use(VueI18n);
const messages = {
  en,
  id,
  es,
  pt,
  zh
};

const userLang = navigator.language || navigator.userLanguage; // 获取用户语言环境
const langMap = {
  'id': 'id', // 印尼语
  'pt': 'pt', // 葡萄牙语
  'es': 'es', // 西班牙语
  // 'zh': "zh"
};
// 获取浏览器语言的前两位
const langPrefix = userLang.split('-')[0].toLowerCase();
// 判断 langPrefix 是否在 langMap 中，如果没有匹配到，则使用默认的 'en'
const defaultLang = 'es'; // 默认语言为英语
const locale = langMap[langPrefix] || defaultLang; // 根据语言环境选择语言

const i18n = new VueI18n({
  locale, // 设定语言环境
  messages,
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

/* eslint-disable no-new */
new Vue({
  i18n,
  render: h => h(App),
  router: router,
  store: store,
}).$mount('#app');
